/*(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define([], function () {
      return root.returnExportsGlobal = factory()
    })
  } else if (typeof exports === 'object') {
    // Node. Does not work with strict CommonJS, but
    // only CommonJS-like enviroments that support module.exports,
    // like Node.
    module.exports = factory()
  } else {
    root['Chartist.plugins.ctLineLabels'] = factory()
  }
}(this, function () {
  /**
   * Chartist.js plugin to display a data label on top of the points in a line chart.
   *
   */
/* global Chartist */
(function (window, document, Chartist) {
  'use strict';
  // Todo aufräumen
  var defaultOptions = {
    labelClass: 'ct-label',
    labelOffset: {
      x: 0,
      y: -10
    },
    textAnchor: 'middle',
    labelInterpolationFnc: Chartist.noop
  };

  // local function
  function doPolyline(linearray) {
    var ret = '';
    for (var i = 0; i < linearray.length; i += 1) {
      ret += linearray[i][0].toString() + ',' + linearray[i][1].toString() + ' '
    }
    return ret
  }
  //Plugin
  Chartist.plugins = Chartist.plugins || {};
  Chartist.plugins.ctPieLinedLabels = function (options) {
    // local Data
    var chartinfo = {
      nr_labels: 0,
      nr_rlabels: 0,
      nr_llabels: 0,
      chart_radius: 0,
      chart_width: 0,
      chart_height: 0,
      abstand_a: 10,
      abstand_b: 10,
      x_2: 0,
      x_1: 0,
      todraw: [],
      todrawnr: []
    };
    
    options = Chartist.extend({}, defaultOptions, options, chartinfo);
    return function ctPieLinedLabels(chart) {
      if (chart instanceof Chartist.Pie) {
        chart.on('draw', function (data) {
          if (data.type === 'label') {
            /* 
              type: 'label',
              index: i,
              group: labelsGroup,
              element: labelElement,
              text: '' + interpolatedValue,
              x: labelPosition.x,
              y: labelPosition.y
            */
            //alert(options.nr_llabels);
            if (options.todraw[data.index] == 1) {
              // Verschieben des Labels
              var vert = options.chart_height / options.nr_rlabels;
              data.element.attr({
                dx: options.x2,
                dy: options.todrawnr[data.index] * vert + vert / 2,
                'text-anchor': 'start',
                class: options.labelClass + ' ct-label--lined'
              });
              // Linie hinzufügen
              var line = [
                [
                  options.x2 + data.element.width(),
                  options.todrawnr[data.index] * vert + vert / 2 + 4
                ],
                [
                  options.x2 - 3,
                  options.todrawnr[data.index] * vert + vert / 2 + 4
                ],
                [
                  data.x,
                  data.y
                ]
              ];
              var str = {};
              str.points = doPolyline(line);
              var lineElement = data.group.elem('polyline', str, 'ct-LabelLine')
            } else if (options.todraw[data.index] == 2) {
              // Verschieben des Labels
              var vert = options.chart_height / options.nr_llabels;
              data.element.attr({
                dx: options.x1,
                dy: (options.nr_llabels - (options.todrawnr[data.index] + 1)) * vert + vert / 2,
                'text-anchor': 'end',
                class: options.labelClass + ' ct-label--lined'
              });
              // Linie hinzufügen
              var line = [
                [
                  options.x1 - data.element.width(),
                  (options.nr_llabels - (options.todrawnr[data.index] + 1)) * vert + vert / 2 + 4
                ],
                [
                  options.x1 + 3,
                  (options.nr_llabels - (options.todrawnr[data.index] + 1)) * vert + vert / 2 + 4
                ],
                [
                  data.x,
                  data.y
                ]
              ];
              var str = {};
              str.points = doPolyline(line);
              var lineElement = data.group.elem('polyline', str, 'ct-LabelLine')
            } else {
              var x = data.element._node.removeChild(data.element._node.firstChild)
            }
          }
          if (data.type === 'slice') {
            /*
              type: 'slice',
              value: dataArray[i],
              totalDataSum: totalDataSum,
              index: i,
              meta: series.meta,
              series: series,
              group: seriesGroups[i],
              element: pathElement,
              path: path.clone(),
              center: center,
              radius: radius,
              startAngle: startAngle,
              endAngle: endAngle
            */
            if (data.index == 0) {
              //initialisierung der Chart-Abmessungen. TODO: besseren Ort suchen.
              options.chart_radius = data.radius;
              options.chart_width = chart.container.clientWidth;
              options.chart_height = chart.container.clientHeight;
              options.x1 = options.chart_width / 2 - options.chart_radius - options.abstand_b;
              options.x2 = options.chart_width / 2 + options.chart_radius + options.abstand_b
            }
          }
        });
        chart.on('created', function (data) {
        });
        chart.on('data', function (data) {
          if (data.type === 'update') {
          }
          if (data.type === 'initial') {
            //find number of labels to draw on the right site
            var sum = 0;
            options.nr_labels = data.data.series.length;
            for (var i = 0; i < options.nr_labels; i += 1) {
              sum += data.data.series[i]
            }
            var mid = sum / 2;
            var inc = 0;
            for (i = 0; i < options.nr_labels; i += 1) {
              var old = inc;
              inc += data.data.series[i];
              if ((old + inc) / 2 <= mid) {
                options.todraw[i] = data.data.labels[i].length > 0 ? 1 : 0;
                if (options.todraw[i]) {
                  options.todrawnr[i] = options.nr_rlabels;
                  options.nr_rlabels += 1
                }
              } else {
                options.todraw[i] = data.data.labels[i].length > 0 ? 2 : 0;
                if (options.todraw[i]) {
                  options.todrawnr[i] = options.nr_llabels;
                  options.nr_llabels += 1
                }
              }
            }
          }
        });
        chart.on('optionsChanged', function (data) {
        })
      }
    }
  }
}(window, document, Chartist))